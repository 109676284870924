import React from "react"
import Layout from "../components/layout"
import { Row, Col, Divider, Icon, Tooltip } from "antd"
import contactUsStyles from "../styles/contact_us.module.scss"

const ContactUs = () => {
  return (
    <Layout>
      <Row align="middle" justify="center" gutter={[0, 48]}>
        <Col span={24}>
          <div className={contactUsStyles.heroImage}>
            <div className={contactUsStyles.heroText}>
              <h2>Get CARE today...</h2>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 48]}>
        <Col span={20} offset={2} align="center">
          <h2>
            We work hard to offer the best comprehensive care
            <br /> by providing undivided attention to our patients.
          </h2>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[0, 48]}>
        <Col
          span={12}
          offset={4}
          align="center"
          xs={{ span: 20, offset: 2 }}
          md={{ span: 10, offset: 2 }}
          lg={{ span: 12, offset: 4 }}
        >
          <div className={contactUsStyles.map}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3667.3175575686932!2d72.614581!3d23.195095!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9bec903781c72aec!2sAnand%20Multispeciality%20Hospital!5e0!3m2!1sen!2sus!4v1577946885982!5m2!1sen!2sus"
              frameborder="0"
            />
          </div>
        </Col>
        <Col
          span={4}
          offset={1}
          align="center"
          xs={{ span: 20, offset: 2 }}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 4, offset: 1 }}
        >
          <h4>Anand Multi-Speciality Hospital & Research Centre</h4>
          <Row gutter={[0, 0]}>
            <Col span={1} offset={1}>
              <Icon type="home" className={contactUsStyles.icon} />
            </Col>
            <Col span={16} offset={4}>
              <p>
                4th Floor Sarthak Mall, <br />
                Mahatma Mandir Road, <br />
                Sargasan Cross Road,
                <br /> Gandhinagar, <br />
                Gujarat 382421
              </p>
            </Col>
          </Row>
          <br />
          <Row gutter={[0, 0]}>
            <Col span={1} offset={1}>
              <Icon type="phone" className={contactUsStyles.icon} />
            </Col>
            <Col span={12} offset={4}>
              <p>
                <Tooltip placement="right" title="Click to book an appointment">
                  <a href="tel:+91-6351901836">6351901836</a>
                </Tooltip>
              </p>
            </Col>
          </Row>
          <br />
          <Row gutter={[0, 0]}>
            <Col span={1} offset={1}>
              <Icon type="facebook" className={contactUsStyles.icon} />
            </Col>
            <Col span={12} offset={4}>
              <p>
                <Tooltip placement="right" title="Click to follow on Facebook">
                  <a
                    href="https://www.facebook.com/drrajendra.anand"
                    target="_blank"
                  >
                    Follow Us!
                  </a>
                </Tooltip>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default ContactUs
